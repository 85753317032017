<template>
  <div class="card">
    <div class="flex flex-row justify-content-between my-3">
      <span class="text-3xl font-semibold align-self-center"
        >Project management</span
      >
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          type="text"
          v-model="searchValue"
          @change="loadData(searchValue)"
          placeholder="Search"
        />
      </span>
    </div>
    <div class="relative">
      <div class="overflow-scroll relative box pt-7 px-3" style="height: 700px">
        <div class="flex" style="width: 1800px">
          <div
            v-for="state in landscaper"
            :key="state.name"
            class="flex flex-column surface-200 border-round p-2 m-2"
            style="width: 290px"
          >
            <div
              class="sticky box-header border-round px-3 py-2 text-white text-lg font-semibold flex justify-content-between align-items-center"
              :style="{ backgroundColor: '#7F2DE9', width: '270px' }"
            >
              <span>{{ state.name }}</span>
              <span
                class="py-2 px-3 border-round text-white text-sm"
                style="background-color: #ffffff36"
                >{{ state.list.length }}</span
              >
            </div>
            <div
              class="bg-white w-full mt-2 border-round p-3 cursor-pointer"
              v-for="el in state.list"
              :key="el.HortusCode"
              @click="$router.push(`/projects/${el.id}`)"
            >
              <div class="flex flex-column">
                <div class="flex flex-row justify-content-between">
                  <span class="text-2xl font-semibold">{{
                    el.HortusCode
                  }}</span>
                  <span class="text-sm">{{ formatDate(el.date_created) }}</span>
                </div>

                <div>
                  <span class="text-lg mt-1" v-if="el.Mood">{{
                    el.Mood.Name
                  }}</span>
                </div>
                <div v-if="el.Comment">
                  <span
                    class="ellipsis my-3 text-base"
                    v-if="i18n.locale.value == 'fr'"
                    >{{ el.Comment }}</span
                  >
                  <span class="ellipsis my-3 text-base" v-else>{{
                    el.Translations[0].Comment
                  }}</span>
                </div>

                <div class="flex align-items-center" v-if="el.HortusClient">
                  <Avatar
                    icon="pi pi-user"
                    class="mr-2"
                    style="background-color: rgb(199 214 246); color: #535cc1"
                    shape="circle"
                  />
                  <div class="flex flex-column">
                    <span class="text-sm font-semibold"
                      >{{ el.HortusClient.Firstname }}
                      {{ el.HortusClient.Lastname }}</span
                    >
                    <span class="text-sm">{{ el.HortusClient.Email }}</span>
                  </div>
                </div>
                <Divider></Divider>
                <div
                  class="flex flex-row justify-content-between align-items-center"
                >
                  <div class="flex flex-row">
                    <div class="mr-3">
                      <i class="pi pi-image" style="font-size: 16px"></i>
                      <span class="ml-1">{{ el.Pictures.length }}</span>
                    </div>
                    <div class="mr-3">
                      <i class="pi pi-file" style="font-size: 16px"></i>
                      <span class="ml-1">{{ el.Documents.length }}</span>
                    </div>
                  </div>
                  <span
                    class="text-sm flex flex-row align-items-center"
                    v-if="el.LandscaperDeadline"
                  >
                    <Icon
                      icon="healthicons:i-schedule-school-date-time-outline"
                      width="26"
                      height="26"
                    />
                    {{ elapsedTime(el.LandscaperDeadline) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="absolute" style="top: 14px; right: 20px">
        <div class="flex flex-row" style="height: 37px">
          <Button
            type="button"
            class="mr-4"
            icon="pi pi-angle-down"
            label="Filter"
            @click="toggle"
            iconPos="right"
            aria:haspopup="true"
            aria-controls="overlay_panel"
          />

          <OverlayPanel
            ref="op"
            appendTo="body"
            :showCloseIcon="false"
            id="overlay_panel"
          >
            <div class="flex flex-column">
              <div class="mb-2">
                <h6>{{ i18n.$t("partner") }}</h6>
                <Dropdown
                  v-model="selectedPartner"
                  :options="partners"
                  optionLabel="Name"
                  :placeholder="
                    i18n.$t('select_a') + i18n.$t('partner').toLowerCase()
                  "
                  @change="loadData(searchValue)"
                >
                  <template #value="slotProps">
                    <div
                      class="partner-item flex flex-row align-items-center"
                      v-if="slotProps.value"
                    >
                      <img
                        v-if="slotProps.value.Logo !== '*'"
                        :src="getPreview(slotProps.value.Logo)"
                      />
                      <div>{{ slotProps.value.Name }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="partner-item" v-if="slotProps.value">
                      <img
                        v-if="slotProps.value.Logo !== '*'"
                        :src="getPreview(slotProps.value.Logo)"
                      />
                      <div>{{ slotProps.value.Name }}</div>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="mb-2">
                <h6>{{ i18n.$t("style") }}</h6>
                <Dropdown
                  v-model="selectedMood"
                  :options="moods"
                  :optionLabel="i18n.locale.value === 'en' ? 'en' : 'fr'"
                  :placeholder="
                    i18n.$t('select_a') + i18n.$t('style').toLowerCase()
                  "
                  @change="loadData(searchValue)"
                />
              </div>
            </div>
          </OverlayPanel>
          <SelectButton
            v-if="active === 0"
            @change="test(landscaper)"
            v-model="optionValue"
            :options="options"
          >
            <template #option="slotProps">
              <i :class="slotProps.option.icon"></i>
            </template>
          </SelectButton>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useProcess } from "../../compositions/useProcess";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useProjectService } from "../../compositions/services/useProjectService";
import { useI18n } from "../../../src/i18nPlugin";
import router from "@/router";
import usePreview from "../../compositions/usePreview";

export default {
  setup() {
    const { getPreview } = usePreview();
    const currentDate = new Date();
    const selectedProjects = ref([]);
    const projectsDone = ref([]);
    const otherProjects = ref([]);
    const selectedPartner = ref({ Name: "*", Logo: "*", id: "*" });
    const selectedStatus = ref("Done");
    const selectedMood = ref({
      en: "*",
      fr: "*",
      id: "*",
    });
    const currentProject = ref();
    const op = ref(null);
    const searchValue = ref("");
    const filters = ref({
      HortusCode: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "client.Username": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "client.Email": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    const loading = ref(true);
    const i18n = useI18n();
    const landscaper = ref([]);
    const {
      partners,
      projects,
      moods,
      getProjectLandscaper,
      getAllLandscaper,
      getPartners,
      getMoods,
    } = useProjectService();
    const options = ref([
      { icon: "pi pi-bars", value: "list" },
      { icon: "pi pi-th-large", value: "column" },
    ]);
    const optionValue = ref({ icon: "pi pi-th-large", value: "column" });
    const active = ref(0);
    const toggle = (event) => {
      op.value.toggle(event);
    };

    function isRunningRow(data) {
      if (data.hasError) {
        return "has-error";
      }
      return data.state === "running" ? "is-running" : null;
    }

    function getDetail(event) {
      router.push(`/projects/${event.data.id}`);
    }
    const formatDate = (value) => {
      return value.toLocaleDateString(i18n.locale.value, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };
    function elapsedTime(date) {
      const diff = new Date(date) - currentDate;

      const intervals = {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24,
      };

      const days = Math.ceil(diff / intervals.day);
      if (days >= 360) {
        return `${Math.floor(days / 365)} ${i18n.$t("year")}${
          Math.floor(days / 365) > 1 ? "s" : ""
        }`;
      } else if (days >= 1) {
        return `${days} ${i18n.$t("days")}`;
      } else if (days == 0) {
        return i18n.$t("today");
      } else if (days === -1) {
        return i18n.$t("yesterday");
      } else {
        if (i18n.locale.value === "en") {
          return `${days} ${i18n.$t("days")} ago`;
        } else {
          return `il y'a ${days} ${i18n.$t("days")}`;
        }
      }
    }

    getAllLandscaper(true).then((data) => {
      data.data.forEach((el) => {
        landscaper.value.push({
          name:
            (el.first_name ? el.first_name : "") +
            " " +
            (el.last_name ? el.last_name : ""),
          list: [],
        });
      });
    });

    function loadData(search) {
      getProjectLandscaper(
        selectedPartner.value.id,
        "*",
        selectedMood.value.id,
        search
      )
        .then(() => {
          landscaper.value.forEach((element) => {
            projects.value.forEach((el) => {
              if (
                (el.LandscaperUser.first_name
                  ? el.LandscaperUser.first_name
                  : "") +
                  " " +
                  (el.LandscaperUser.last_name
                    ? el.LandscaperUser.last_name
                    : "") ==
                element.name
              ) {
                element.list.push(el);
              }
            });
          });

          landscaper.value.sort((a, b) => b.list.length - a.list.length);
        })
        .catch(() => {});
    }

    getPartners();
    getMoods();

    loadData("");

    function test(value) {
      console.log(value);
    }

    return {
      op,
      i18n,
      selectedProjects,
      currentDate,
      landscaper,
      partners,
      moods,
      projectsDone,
      otherProjects,
      options,
      optionValue,
      active,
      toggle,
      isRunningRow,
      ...useProcess(),
      test,
      loadData,
      projects,
      selectedPartner,
      selectedStatus,
      selectedMood,
      searchValue,
      currentProject,
      filters,
      loading,
      getPreview,
      getDetail,
      formatDate,
      elapsedTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.img_header {
  width: 300px;
  height: 300px;
  margin-bottom: -80px;
  margin-top: -76px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box {
  .box-header {
    position: -webkit-sticky;
    position: sticky;
    top: 8px;
    z-index: 10;
  }
  .next-slide {
    top: 0;
    right: -13px;
    filter: blur(20px);
    visibility: hidden;
    transition: visibility 20ms;
  }
  &:hover {
    .next-slide {
      visibility: visible;
    }
  }

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 14px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

::v-deep(.is-running) {
  background-color: rgba(19, 160, 0, 0.15) !important;
}
::v-deep(.has-error) {
  background-color: rgba(160, 0, 0, 0.137) !important;
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-dropdown {
  min-width: 200px;
}
.p-chip {
  border-radius: 3px;
  font-size: 12px;
}

.chip-clientwip {
  background: var(--pink-200);
  color: white;
}
.chip-clientdone {
  background: var(--pink-400);
  color: white;
}
.chip-landscaperwip {
  background: var(--orange-300);
  color: white;
}
.chip-landscaperdone {
  background: var(--orange-500);
  color: white;
}
.chip-review {
  background: var(--blue-600);
  color: white;
}
.chip-done {
  background: var(--green-600);
  color: white;
}

.partner-item {
  img {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
  }
}
</style>
