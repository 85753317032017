import http from "../../services/http";
import {
  ref,
  toRef,
  reactive
} from 'vue'

const isProjectReady = ref(false)

const store = reactive({
  projects: [],
  project: {},
  partners: [],
  moods: []
})

function setProjectValue(data) {
  store.projects = data || []
  store.projects.forEach(
    (project) => (project.date_created = new Date(project.date_created))
  );
}

function getAll() {
  return new Promise((resolve, reject) => {
    // http.core.get("/api/projects")
    http.core.get("/data/items/Hortus_Projects?fields=HortusClient.*,Mood.*,*")
      .then(({
        data
      }) => {
        getPartners()
        setProjectValue(data.data)
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function getById(id) {
  isProjectReady.value = false
  return new Promise((resolve, reject) => {
    http.core.get(`/data/items/Hortus_Projects/${id}?fields=*.*,TodoList.I18n.*,HortusClient.*,Documents.*,Partner.*,Pictures.Picture.*,Pictures.I18n.*,Pictures.*,HortusBooks.*,HortusBooks.PlantsShoppingList.*,HortusBooks.PlantsShoppingList.Plant.*,HortusBooks.MaterialsShoppingList.*,HortusBooks.MaterialsShoppingList.Item.*,HortusBooks.Plans.*,HortusBooks.BeforeAfter.*,Translations.*,*,Mood.*,Mood.I18n.*,TodoList.*,TodoList.ItemOption.TodoListItem.*,TodoList.ItemOption.TodoListItem.Options.*`)
      .then(({
        data
      }) => {
        store.project = data.data
        isProjectReady.value = true
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function getFileById(id) {
  return new Promise((resolve, reject) => {
    http.core.get(`/data/files/${id}?fields=uploaded_by.*,*`)
      .then(({
        data
      }) => {
        resolve(data)
        // await getPartners()
        // setProjectValue(data.data)
        // resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}


async function getPlantsByMoods(id, links, value) {
  const ids = [id].concat(links || [])

  const filter = {
    Moods: {
      Moods_id: {
        id: {
          _in: ids
        }
      }
    }
  }

  const {
    data
  } = await http.core.get(`/data/items/Inventaire_Plants?search=${value||''}&limit=1024&filter=${JSON.stringify(filter)}`)
  return data
}


function getPartners() {
  return new Promise((resolve, reject) => {
    http.core.get("/data/items/Partners")
      .then(({
        data
      }) => {
        store.partners = data.data
        store.partners.unshift({
          Name: "*",
          Logo: "*",
          id: "*"
        })
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function getValueSearch(value) {
  return new Promise((resolve, reject) => {
    http.core.get(`/data/items/Hortus_Projects?search=${value}&fields=HortusClient.*,*`)
      .then(({
        data
      }) => {
        setProjectValue(data.data)
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function getMoods() {
  return new Promise((resolve, reject) => {
    http.core.get(`/data/items/Moods?fields=I18n.*,*`)
      .then(({
        data
      }) => {
        let array = []
        array.push({
          en: "*",
          fr: "*",
          id: "*"
        })
        data.data.forEach(el => {
          if (el.I18n.length === 0) {
            array.push({
              en: el.Iso,
              fr: el.Name,
              id: el.id
            })
          } else {
            array.push({
              en: el.I18n[0].Name,
              fr: el.Name,
              id: el.id
            })
          }
          store.moods = array
        });
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function getFilteredAll(filt1, filt2, filt3, search) {
  const query = [
    'fields=*.*',
    'limit=-1'
  ]


  if (filt1 !== "*") {
    query.push(`filter[Partner][_eq]=${filt1}`)
  }

  if (filt2 !== "*") {
    query.push(`filter[Status][_eq]=${encodeURIComponent(filt2)}`)
  }

  if (filt3 !== "*") {
    query.push(`filter[Mood][_eq]=${filt3}`)
  }

  if (search) {
    query.push(`search=${search}`)
  }

  return new Promise((resolve, reject) => {
    http.core.get(`/data/items/Hortus_Projects?${query.join('&')}`)
      .then(({
        data
      }) => {
        console.log(data.data);
        setProjectValue(data.data)
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })

}

function getProjectLandscaper(filt1, filt2, filt3, search) {
  let p1 = "";
  let p2 = "";
  let p3 = "";

  if (filt1 === "*") {
    p1 = "";
  } else {
    p1 = `&filter[Partner][_eq]=${filt1}`;
  }

  if (filt2 === "*") {
    p2 = "";
  } else {
    p2 = `&filter[Status][_eq]=${filt2}`;
  }

  if (filt3 === "*") {
    p3 = "";
  } else {
    p3 = `&filter[Mood][_eq]=${filt3}`;
  }

  return new Promise((resolve, reject) => {
    http.core.get(`/data/items/Hortus_Projects?search=${search}${p1}${p2}${p3}&filter[LandscaperUser][_nnull]=true&filter[Status][_neq]=done&fields=*.*&limit=-1`)
      .then(({
        data
      }) => {
        setProjectValue(data.data)
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

function getAllLandscaper(active) {
  return new Promise((resolve, reject) => {
    // http.core.get("/api/projects")
    http.core.get(`/data/users?filter[role][_eq]=40ea7edb-3eb1-4165-a4b6-520afd42b936&filter[status][_eq]=${active ? 'active' : 'inactive'}`)
      .then(({
        data
      }) => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function useProjectService() {
  return {
    projects: toRef(store, 'projects'),
    project: toRef(store, 'project'),
    partners: toRef(store, 'partners'),
    moods: toRef(store, 'moods'),
    isProjectReady,
    getAll,
    getById,
    getValueSearch,
    getFileById,
    getFilteredAll,
    getPlantsByMoods,
    getProjectLandscaper,
    getAllLandscaper,
    getPartners,
    getMoods
  }
}